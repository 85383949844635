import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";


export default function MenuButton (props) {
    const { item } = props
    return (
        <Link to={item.link} onClick={props.onClick}>
            <MenuItem title={item.title}>
            <img src={item.icon} alt={item.title} />
            {item.title}
            </MenuItem>
        </Link>
    )
}

const MenuItem = styled.div`
    color: rgba(255,255,255,0.7);
    display: grid;
    grid-template-columns: 24px auto;
    /* gap: 10px; */
    gap: ${props => (props.title ? "10px" : "0px")}; //Checks if there is a title, if yes then sets 10px or else 0px
    align-items: center;
    padding: 10px;
    transition: 0.5s ease-out; // Transition should always be at the parent otherwise it'll only happen on mouseover
    border-radius: 10px;

    :hover{
        background: rgba(255, 255, 255, 0.1);
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1), inset 0px 0px 0px 0.5px rgba(255, 255, 255, 0.2);
        
    }
`
