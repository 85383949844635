export const menuData = [
    { title: "Courses", icon: "/images/icons/courses.svg", link: "/courses"}, 
    { title: "Tutorials", icon: "/images/icons/tutorials.svg", link: "/tutorials"}, 
    { title: "Pricing", icon: "/images/icons/pricing.svg", link: "/pricing"}, 
    { title: "", icon: "/images/icons/search.svg", link: "/search"}, 
    { title: "", icon: "/images/icons/account.svg", link: "/account"}, 
]

export const tooltipData = [
    { title: "Profile", icon: "/images/icons/profile.svg", link: "/profile" },
    { title: "Settings", icon: "/images/icons/settings.svg", link: "/settings" },
    { title: "Sign out", icon: "/images/icons/signout.svg", link: "/logout" },
  ]